import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import CsvFileUpload from "../Components/CsvFileUpload";
import strings from "../localization";

const Home = () => {
  const authUser = useSelector((state) => state.AuthReducer.authUser);
  const navigate = useNavigate();
  const [userQueries, setUserQueries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const limit = 10;

  const fetchUserQueries = async (page = 0) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/user-queries?user_id=${authUser.id}&page=${page + 1}&limit=${limit}`);
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      if (response.status === 403) {
        navigate('/');
        return;
      }
      
      const data = await response.json();
      setUserQueries(data.queries);
      setPageCount(Math.ceil(data.totalCount / limit));

    } catch (error) {
      console.error('Failed to fetch user queries:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!authUser?.id) {
      navigate('/login');
    } else {
      fetchUserQueries();

    }
  }, [authUser, navigate]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchUserQueries(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    if (pageCount <= 5) {
      for (let i = 0; i < pageCount; i++) {
        pageNumbers.push(
          <li key={i} className={currentPage === i ? "active" : ""}>
            <button onClick={() => handlePageChange(i)}>{i + 1}</button>
          </li>
        );
      }
    } else {
      pageNumbers.push(
        <li key={0} className={currentPage === 0 ? "active" : ""}>
          <button onClick={() => handlePageChange(0)}>1</button>
        </li>
      );

      if (currentPage > 2) {
        pageNumbers.push(<li key="ellipsis-start">...</li>);
      }

      for (let i = Math.max(1, currentPage - 1); i <= Math.min(pageCount - 2, currentPage + 1); i++) {
        pageNumbers.push(
          <li key={i} className={currentPage === i ? "active" : ""}>
            <button onClick={() => handlePageChange(i)}>{i + 1}</button>
          </li>
        );
      }

      if (currentPage < pageCount - 3) {
        pageNumbers.push(<li key="ellipsis-end">...</li>);
      }

      pageNumbers.push(
        <li key={pageCount - 1} className={currentPage === pageCount - 1 ? "active" : ""}>
          <button onClick={() => handlePageChange(pageCount - 1)}>{pageCount}</button>
        </li>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="main-container">
      <style>
        {`
          .pagination {
            display: flex;
            justify-content: center;
            list-style: none;
            padding: 0;
          }
          .pagination li {
            margin: 0 5px;
          }

          .pagination li button {
            color: #007bff;
            text-decoration: none;
            padding: 8px 12px;
            border: 1px solid #ddd;
            border-radius: 5px;
            background: none;
            cursor: pointer;
          }

          .pagination li.active button {
            background-color: #007bff;
            color: white;
            border: 1px solid #007bff;
          }

          .pagination li.disabled button {
            color: #ccc;
            cursor: not-allowed;
          }
          .user-query-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px;
            border-bottom: 2px solid #f0f0f0;
          }
          .user-query-item:nth-child(odd) {
            background-color: #e0e0e0;
          }

          .user-query-link {
            text-decoration: none;
            color: #333;
            font-weight: bold;
          }
        `}
      </style>

      {authUser?.id && (
        <p>Please upload your application list in CSV format.</p>
      )}
      <CsvFileUpload />

      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <hr />
          <h2>User Queries</h2>
          {userQueries.length > 0 ? (
            <div>
              <ul style={{ listStyleType: 'none', margin: 'auto', display: 'block', width: '33%' }}>
                {userQueries.map((query) => (
                  <li key={query.query_id} className="user-query-item">
                    <a href={`/score/${query.query_id}`} className="user-query-link">
                      {query.name}_{query.query_id}
                    </a>
                  </li>
                ))}
              </ul>
              <ul className="pagination">
                <li className={currentPage === 0 ? "disabled" : ""}>
                  <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0}>Previous</button>
                </li>
                {renderPageNumbers()}
                <li className={currentPage === pageCount - 1 ? "disabled" : ""}>
                  <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === pageCount - 1}>Next</button>
                </li>
              </ul>
            </div>
          ) : (
            <p>No queries found.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Home;
