import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import {Tooltip} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// Define a function to map status IDs to colors
const getStatusColor = (statusId) => {
  switch (statusId) {
    case 1:
      return 'green'; // Active patent
    case 2:
      return 'blue';  // Active application
    case 3:
      return 'gray';  // Expired patent
    case 4:
      return 'orange'; // Abandoned application
    default:
      return 'transparent'; // Default case (no status)
  }
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: '1rem',
    padding: '2px',
    whiteSpace: 'normal', // Allows the text to wrap
    //overflow: 'hidden', // Keeps the content confined to the cell
    //textOverflow: 'ellipsis', // Adds an ellipsis to overflowing text
    //width: '30px !important',
    //width: '30px !important',

  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.action.href,
  },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    padding: '7px 2px 7px 2px',
  },

}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    fontSize: 11,
    lineHeight: '1rem',
    padding: '2px',
    whiteSpace: 'normal', // Allows the text to wrap
    //overflow: 'hidden', // Keeps the content confined to the cell
    //textOverflow: 'ellipsis', // Adds an ellipsis to overflowing text
    //width: '30px !important',

  [`&.${tableCellClasses.body}`]: {
  },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#4789cc",
    color: theme.palette.secondary.contrastText,
    padding: '7px 2px 7px 2px',
  },

}));

const StyledTableCell3 = styled(TableCell)(({ theme }) => ({
    fontSize: 11,
    lineHeight: '1rem',
    padding: '2px',
    whiteSpace: 'normal', // Allows the text to wrap
    //overflow: 'hidden', // Keeps the content confined to the cell
    //textOverflow: 'ellipsis', // Adds an ellipsis to overflowing text
    //width: '30px !important',

  [`&.${tableCellClasses.body}`]: {
  },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#79aed9",
    color: theme.palette.secondary.contrastText,
    padding: '7px 2px 7px 2px',
  },

}));

const rejectionTypeMapping = {
  1: '35 U.S. Code § 102',
  2: '35 U.S. Code § 103 - Primary Reference',
  3: '35 U.S. Code § 103 - Secondary Reference',
  // Add more mappings if needed
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

// Apply fixed table layout
const StyledTable = styled(Table)({
//  tableLayout: 'fixed',
//  width: '100%', // Ensure the table spans the full container width
});


const firstLayerColor = "#1565C0"; // Primary blue
const secondLayerColor = "#F9F9F9"; // Slightly lighter blue
const thirdLayerColor = "#FFFFFF"; // Even lighter blue

function Row({ row, onSort}) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <StyledTableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)} style={{ padding: 0 }}>{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
        </StyledTableCell>

        <StyledTableCell component="th" scope="row">{row['Cited App No']}</StyledTableCell>
        <StyledTableCell >{row['Cited Pub No']}</StyledTableCell>
        <StyledTableCell>{row['Cited Grant No']}</StyledTableCell>
        <StyledTableCell sx={{width: '230px !important' }}>
          <Tooltip title={row['B title']} placement="top" arrow>
            <span>
              {row['B title'].length > 30 ? row['B title'].substring(0,30)+ '...' : row['B title']}
            </span>
          </Tooltip>
        </StyledTableCell>
        <StyledTableCell>{row['B art unit']}</StyledTableCell>
        <StyledTableCell sx={{width: '230px !important' }}>
          <Tooltip title={row['B assignee|applicant']} placement="top" arrow>
            <span>
              {row['B assignee|applicant'].length > 30 ? row['B assignee|applicant'].substring(0,30)+ '...' : row['B assignee|applicant']}
            </span>
          </Tooltip>
	</StyledTableCell>
        <StyledTableCell>{row['B uspc']}</StyledTableCell>
        <StyledTableCell>{row['B sts date']}</StyledTableCell>
        <StyledTableCell>{row['B priority date']}</StyledTableCell>
        <StyledTableCell>{row['B grant date']}</StyledTableCell>
        <StyledTableCell>{row['B pub date']}</StyledTableCell>
        <StyledTableCell sx={{width: '230px !important' }}>
          <Tooltip title={row['B inventor|applicant']} placement="top" arrow>
            <span>
              {row['B inventor|applicant'].length > 30 ? row['B inventor|applicant'].substring(0,30)+ '...' : row['B inventor|applicant']}
            </span>
          </Tooltip>
        </StyledTableCell>
        <StyledTableCell sx={{width: '30px !important', textAlign: '-webkit-center !important' }}>
  	  <Box
    	    width={12}  // Adjust width of the box
            height={12}  // Adjust height of the box
            bgcolor={getStatusColor(row['B app sts ID'])}  // Dynamic background color
            borderRadius="4px"  // Add rounded corners
  	  />

	</StyledTableCell>
        <StyledTableCell sx={{width: '30px !important' }}></StyledTableCell>
        <StyledTableCell sx={{width: '30px !important' }}></StyledTableCell>
        <StyledTableCell sx={{width: '30px !important' }}>{row['Sum Block Score']}</StyledTableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={18}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={0}
              sx={{
                padding: 0,  // Adds padding inside the nested table
                backgroundColor: secondLayerColor,  // Light blue background for nested table
                border: `1px solid #4789CC`,  // Border for visual distinction
                borderRadius: 0,  // Rounded corners for visual effect
              }}
	    >
              <StyledTable aria-label="citing">
                <TableHead>
                  <TableRow>
                    <StyledTableCell2></StyledTableCell2>
                    <StyledTableCell2>App #</StyledTableCell2>
                    <StyledTableCell2>Pub #</StyledTableCell2>
                    <StyledTableCell2>Pat #</StyledTableCell2>
                    <StyledTableCell2 sx={{width: '230px !important' }}>Title</StyledTableCell2>
                    <StyledTableCell2>Art Unit</StyledTableCell2>
                    <StyledTableCell2 sx={{width: '230px !important' }}>Assignee/Applicant</StyledTableCell2>
                    <StyledTableCell2>USPC</StyledTableCell2>
                    <StyledTableCell2>Status date</StyledTableCell2>
                    <StyledTableCell2>Priority Date</StyledTableCell2>
                    <StyledTableCell2>Grant Date</StyledTableCell2>
                    <StyledTableCell2>Pub. Date</StyledTableCell2>
                    <StyledTableCell2  sx={{width: '230px !important' }}>Inventor/Applicant</StyledTableCell2>
                    <StyledTableCell2 sx={{width: '30px !important', textAlign: '-webkit-center !important' }}>Status</StyledTableCell2>
                    <StyledTableCell2 sx={{width: '30px !important', textAlign: '-webkit-center !important' }}></StyledTableCell2>
                    <StyledTableCell2 sx={{width: '30px !important', textAlign: '-webkit-center !important' }}></StyledTableCell2>
                    <StyledTableCell2 sx={{width: '30px !important', textAlign: '-webkit-center !important' }}></StyledTableCell2>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.citingApps.map((citingApp) => (
                    <CitingRow key={citingApp['Citing App No']} row={citingApp} />
                  ))}
                </TableBody>
              </StyledTable>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function CitingRow({ row }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <StyledTableCell2>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            style={{ padding: 0 }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell2>
        <StyledTableCell2 component="th" scope="row">{row['Citing App No']}</StyledTableCell2>
        <StyledTableCell2>{row['Citing Pub No']}</StyledTableCell2>
        <StyledTableCell2>{row['Citing Grant No']}</StyledTableCell2>
        <StyledTableCell2 sx={{width: '230px !important' }}>
	  <Tooltip title={row['A title']} placement="top" arrow>
	    <span>
	      {row['A title'].length > 30 ? row['A title'].substring(0,30)+ '...' : row['A title']}
	    </span>
          </Tooltip>
	</StyledTableCell2>
        <StyledTableCell2>{row['A art unit']}</StyledTableCell2>
        <StyledTableCell2 sx={{width: '230px !important' }}>
          <Tooltip title={row['A assignee|applicant']} placement="top" arrow>
            <span>
              {row['A assignee|applicant'].length > 30 ? row['A assignee|applicant'].substring(0,30)+ '...' : row['A assignee|applicant']}
            </span>
          </Tooltip>
	</StyledTableCell2>
        <StyledTableCell2>{row['A uspc']}</StyledTableCell2>
        <StyledTableCell2>{row['A sts date']}</StyledTableCell2>
        <StyledTableCell2>{row['A priority date']}</StyledTableCell2>
        <StyledTableCell2>{row['A grant date']}</StyledTableCell2>
        <StyledTableCell2>{row['A pub date']}</StyledTableCell2>
        <StyledTableCell2 sx={{width: '230px !important' }}>
          <Tooltip title={row['A inventor|applicant']} placement="top" arrow>
            <span>
              {row['A inventor|applicant'].length > 30 ? row['A inventor|applicant'].substring(0,30)+ '...' : row['A inventor|applicant']}
            </span>
          </Tooltip>
	</StyledTableCell2>
        <StyledTableCell sx={{width: '30px !important', textAlign: '-webkit-center !important' }}>
  	  <Box
    	    width={12}  // Adjust width of the box
            height={12}  // Adjust height of the box
            bgcolor={getStatusColor(row['A app sts ID'])}  // Dynamic background color
            borderRadius="4px"  // Add rounded corners
  	  />

	</StyledTableCell>
        <StyledTableCell2 sx={{width: '30px !important' }}></StyledTableCell2>
        <StyledTableCell2 sx={{width: '30px !important' }}>{row['Block Score']}</StyledTableCell2>
        <StyledTableCell2 sx={{width: '30px !important' }}></StyledTableCell2>
      </StyledTableRow>
      <TableRow>
	<TableCell style={{ padding: 0 }} colSpan={6}>
	</TableCell>
        <TableCell style={{ padding: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={0}
              sx={{
                padding: 0,  // Adds padding inside the nested table
                backgroundColor: thirdLayerColor,  // Light blue background for nested table
                border: `1px solid #79AED9`,  // Border for visual distinction
                borderRadius: 0,  // Rounded corners for visual effect
              }}
            >
              <Table aria-label="mentions">
               <TableHead>
                  <TableRow> 
                    <StyledTableCell3>Mention identificator</StyledTableCell3>
                    <StyledTableCell3>Mail Room Date</StyledTableCell3>
                    <StyledTableCell3 sx={{width: '250px !important' }}>Rejetion Type</StyledTableCell3>
                    <StyledTableCell3 sx={{width: '300px !important' }}>Basis For Rejection</StyledTableCell3>
                    <StyledTableCell3 sx={{width: '36px !important' }}></StyledTableCell3>
                    <StyledTableCell3 sx={{width: '30px !important', textAlign: '-webkit-center !important' }}></StyledTableCell3>
                    <StyledTableCell3 sx={{width: '30px !important', textAlign: '-webkit-center !important' }}></StyledTableCell3>
                    <StyledTableCell3 sx={{width: '30px !important', textAlign: '-webkit-center !important' }}></StyledTableCell3>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.mentions.map((mention) => (
                    <TableRow key={mention.oaid}>
                      <StyledTableCell3>{mention['mention ident']}</StyledTableCell3>
                      <StyledTableCell3>{mention['mail_date']}</StyledTableCell3>
                      <StyledTableCell3 sx={{width: '250px !important' }}>{rejectionTypeMapping[mention['code']]}</StyledTableCell3>
                      <StyledTableCell3 sx={{width: '300px !important' }}>{mention['error code']}</StyledTableCell3>
                      <StyledTableCell3 sx={{width: '36px !important' }}></StyledTableCell3>
                      <StyledTableCell3 sx={{width: '30px !important', textAlign: '-webkit-center !important' }}>{mention['Mention Score']}</StyledTableCell3>
                      <StyledTableCell3 sx={{width: '30px !important', textAlign: '-webkit-center !important' }}></StyledTableCell3>
                      <StyledTableCell3 sx={{width: '30px !important', textAlign: '-webkit-center !important' }}></StyledTableCell3>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function ScoreNestedTable({ data, onSort, sortField, sortDirection  }) {
  return (
    <TableContainer component={Paper}>
      <StyledTable aria-label="collapsible table">
        <TableHead>
          <TableRow>
      <StyledTableCell></StyledTableCell>
      <StyledTableCell onClick={() => onSort('Cited App No')}>
        App #
        {sortField === 'Cited App No' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
      </StyledTableCell>
      <StyledTableCell onClick={() => onSort('Cited Pub No')}>
        Pub #
        {sortField === 'Cited Pub No' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
      </StyledTableCell>
      <StyledTableCell onClick={() => onSort('Cited Grant No')}>
        Pat #
        {sortField === 'Cited Grant No' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
      </StyledTableCell>
      <StyledTableCell sx={{ width: '230px' }} onClick={() => onSort('B title')}>
        Title
        {sortField === 'B title' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
      </StyledTableCell>
      <StyledTableCell onClick={() => onSort('B art unit')}>
        Art Unit
        {sortField === 'B art unit' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
      </StyledTableCell>
      <StyledTableCell sx={{ width: '230px' }} onClick={() => onSort('B assignee|applicant')}>
        Assignee/Applicant
        {sortField === 'B assignee|applicant' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
      </StyledTableCell>
      <StyledTableCell onClick={() => onSort('B uspc')}>
        USPC
        {sortField === 'B uspc' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
      </StyledTableCell>
      <StyledTableCell onClick={() => onSort('B sts date')}>
        Status date
        {sortField === 'B sts date' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
      </StyledTableCell>
      <StyledTableCell onClick={() => onSort('B priority date')}>
        Priority Date
        {sortField === 'B priority date' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
      </StyledTableCell>
      <StyledTableCell onClick={() => onSort('B grant date')}>
        Grant Date
        {sortField === 'B grant date' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
      </StyledTableCell>
      <StyledTableCell onClick={() => onSort('B pub date')}>
        Pub. Date
        {sortField === 'B pub date' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
      </StyledTableCell>
      <StyledTableCell sx={{ width: '230px' }} onClick={() => onSort('B inventor|applicant')}>
        Inventor/Applicant
        {sortField === 'B inventor|applicant' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
      </StyledTableCell>
      <StyledTableCell onClick={() => onSort('B app sts ID')}>
        Status
        {sortField === 'B app sts ID' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
      </StyledTableCell>
      <StyledTableCell onClick={() => onSort('Mention Score')}>
        MS
        {sortField === 'Mention Score' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
      </StyledTableCell>
      <StyledTableCell onClick={() => onSort('Block Score')}>
        DS
        {sortField === 'Block Score' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
      </StyledTableCell>
      <StyledTableCell onClick={() => onSort('Sum Block Score')}>
        USIS
        {sortField === 'Sum Block Score' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
      </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(data).map((row) => (
            <Row key={row['Cited App No']} row={row} />
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
}
