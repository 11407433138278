import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src='https://ipdatalab.com/img/footer-logo.png' alt='IpDataLab logo' />
          <p>
            Recognizing an increase in the value of a patent is as simple as discovering a new link to corresponding commercial activity. Our focus is these links.
          </p>
          <p className="footer-copyright">©2024 IPDataLab</p>
        </div>
        <div className="footer-links">
          <hr className="footer-hr" />
          <h4>Explore</h4>
          <div className="links-wrapper">
            <ul>
              <li><a href='https://ipdatalab.com/purple-book'>PurpleBook Patent Data</a></li>
              <li><a href='https://ipdatalab.com/orange-book'>OrangeBook Patent Data</a></li>
              <li><a href='https://ipdatalab.com/sep'>Standard Essential Patents</a></li>
              <li><a href='https://ipdatalab.com/full-text-office-actions'>Full Text Office Actions</a></li>
              <li><a href='https://ipdatalab.com/mention-db'>US Office Action Mentions</a></li>
            </ul>
            <ul>
              <li><a href='https://ipdatalab.com/'>Home</a></li>
              <li><a href='https://ipdatalab.com/partnerships'>Partnerships</a></li>
              <li><a href='https://ipdatalab.com/about'>About</a></li>
              <li><a href='https://ipdatalab.com/contact'>Contact</a></li>

            </ul>
          </div>
        </div>
        <div className="footer-subscribe">
          <hr className="footer-hr" />
          <h4>Subscribe</h4>
          <p>Get the latest news and updates</p>
          <form className="subscribe-form">
            <input type="email" placeholder="Email Address" />
            <button type="submit" className='footer-button'>Submit</button>
          </form>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
