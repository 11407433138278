import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Role } from "../../Constants/Role";
import { logout } from "../../Services/SecurityService";
import strings from "../../localization";

const Header = () => {
  const authUser = useSelector((state) => state.AuthReducer.authUser);
  const dispatch = useDispatch();

  const handleLogout = async () => {
    logout(dispatch).then(() => {
      window.location = '/login';
    });
  };

  return (
    <div className="header">
      {/* Logo */}
      <NavLink to="/" className="logo-btn">
        <img src="https://ipdatalab.com/img/logo.png" alt="IpDataLab logo" />
        {/* {strings.forms.common.reactApp} */}
        <h1 className="logo-title">APLS</h1>

      </NavLink>

      {/* Header buttons container */}
      <div className="header-buttons-container">
        {authUser?.role && (
          <div>
            {authUser.role === Role.USER && <NavLink to="/board-user" className="home-btn">{strings.pages.boardUser.title}</NavLink>}
            {authUser.role === Role.MODERATOR && <NavLink to="/board-moderator" className="home-btn">{strings.pages.boardModerator.title}</NavLink>}
            {authUser.role === Role.ADMIN && <NavLink to="/board-admin" className="home-btn">{strings.pages.boardAdmin.title}</NavLink>}
          </div>
        )}
        {authUser?.id ? (
          <>
            <NavLink to="/profile">{authUser.username}</NavLink>
            <NavLink onClick={handleLogout}>{strings.forms.common.logout}</NavLink>
          </>
        ) : (
          <>
            <NavLink to="/login">{strings.forms.common.login}</NavLink>
            <NavLink to="/registration">{strings.forms.common.register}</NavLink>
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
