import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
    sr: {
        base: {
            profile: {
                name: 'First Name',
                surname: 'Last Name',
                email: 'E-Mail',
                username: 'Username',
                password: 'Password',
                role: 'Role',
                userType: 'User Type'
            },
            table: {
                actions: 'Actions',
                edit: 'Edit',
                delete: 'Delete'
            },
            filter: {
                sort: {
                    sortType: 'Sort Order',
                    datetimeDesc: 'Descending',
                    datetimeAsc: 'Ascending',
                }
            },
            role: {
                user: 'User',
                moderator: 'Moderator',
                admin: 'Administrator'
            },
            userType: {
                individual: 'Individual',
                company: 'Company',
            }
        },
        forms: {
            common: {
                save: 'Save',
                login: 'Login',
                logout: 'Logout',
                register: 'Register',
                thisFieldIsRequired: 'Mandatory field',
                emailFormatError: 'Please enter valid format of email address',
                reactApp: 'APLS'
            },
            editUser: {
                title: 'Edit User',
            },
            addUser: {
                title: 'Add User',
            }
        },
        components: {
            FileUpload: {
                upload: 'Upload',
                files: 'Files',
                dragDrop: 'Drag and drop files here or click to browse',
            },
            yesNoDialog: {
                yes: 'Yes',
                no: 'No',
                confirmDelete: 'Confirm deletion',
                confirmDeleteMessage: 'Are you sure you want to delete this entry?',
            }
        },
        pages: {
            register: {
                registration: 'Registration',
                alreadyHaveAccount: 'Already registred?'
            },
            login: {
                createNewAccount: 'Create new account',
                wrongUsernameOrPassword: 'Wrong username or password',
            },
            home: {
                title: 'Homepage',
                welcome: 'Welcome'
            },
            score: {
                title: 'Score'
            },
            boardUser: {
                title: 'Users Table'
            },
            boardModerator: {
                title: 'Users Moderation'
            },
            boardAdmin: {
                title: 'Users Administration'
            },
        }
    }
});

export default strings;
