import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import '@fortawesome/fontawesome-free/css/all.min.css'; // for MDBReact which uses Font Awesome
import 'bootstrap-css-only/css/bootstrap.min.css'; // for Bootstrap
import 'mdbreact/dist/css/mdb.css'; // for MDBReact specific styles
import './assets/scss/global.scss';

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <App />
);
